import {
  Button,
  Input,
  Modal,
  TextButton,
} from '@maersktankersdigital/web-components'
import { useEffect, useState } from 'react'

import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  ModalButtonWrapper,
  NewListModalContent,
} from '~pages/pages-behind-login/position-list/position-list-page/modals/new-list-modal/new-list-modal.styles'

interface Props {
  setShowSaveNewListModal: (open: boolean) => void
  showSaveNewListModal: boolean
}

function NewListModal({
  showSaveNewListModal,
  setShowSaveNewListModal,
}: Props) {
  const [listName, setListName] = useState('')
  const { persistActiveView } = usePositionListView()

  useEffect(() => {
    if (showSaveNewListModal) {
      setListName('')
    }
  }, [showSaveNewListModal])

  const handleSaveNewList = () => {
    if (!listName) return

    persistActiveView(listName)
    setShowSaveNewListModal(false)
  }

  const handleCloseModal = () => setShowSaveNewListModal(false)

  return (
    <Modal
      open={showSaveNewListModal}
      handleClose={handleCloseModal}
      variant="secondary"
      title="Add new list"
    >
      <NewListModalContent>
        <Input
          name="listName"
          placeholder="New list name..."
          label="Name the list"
          required
          onChange={(e) => setListName(e.target.value)}
          value={listName}
          autoFocus
        />
        <ModalButtonWrapper>
          <TextButton variant="secondary" onClick={handleCloseModal}>
            Cancel
          </TextButton>
          <Button
            variant="primary"
            disabled={listName.length < 3}
            onClick={handleSaveNewList}
            data-cy="save-new-list"
          >
            Save
          </Button>
        </ModalButtonWrapper>
      </NewListModalContent>
    </Modal>
  )
}

export default NewListModal
