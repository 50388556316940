import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useOutletContext } from 'react-router-dom'

import { AnimatedAlert } from '~components/molecules/animated-alert/animated-alert'
import AnimatedAlertProvider from '~components/molecules/animated-alert/animated-alert-provider'
import AutoCompleteInputProvider from '~components/molecules/auto-complete-input/auto-complete-input-provider'

type OutletContext = {
  myListsButtonRef: React.RefObject<HTMLButtonElement>
  setShowMyLists: React.Dispatch<React.SetStateAction<boolean>>
  showMyLists: boolean
}

function PositionList() {
  const [showMyLists, setShowMyLists] = useState(false)
  const myListsButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <AutoCompleteInputProvider>
      <AnimatedAlertProvider>
        <Helmet>
          <title>InSite Digital | Position List</title>
        </Helmet>
        <Outlet
          context={{
            showMyLists,
            setShowMyLists,
            myListsButtonRef,
          }}
        />
        <AnimatedAlert />
      </AnimatedAlertProvider>
    </AutoCompleteInputProvider>
  )
}

export function useMyLists() {
  return useOutletContext<OutletContext>()
}

export default PositionList
