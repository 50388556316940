import { getLastThreeVoyages } from '~components/organisms/dashboard/dashboard.utils'
import { AreaFilteredVessels } from '~pages/pages-behind-login/position-list/hooks/use-area-filtered-vessels'
import {
  CheckboxValues,
  MultiSelectValues,
} from '~pages/pages-behind-login/position-list/position-list-page/modals/export-modal/export-modal'
import {
  formatDate,
  getCellContent,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import { vesselSpecsOptions } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import {
  Consumption,
  PositionVesselData,
  StatusValues,
  UsCoc,
  WorldArea,
} from '~pages/pages-behind-login/position-list/types/position-list-types'
import { COLORS } from '~theme/colors'
import { Voyage } from '~types/itinerary.types'
import { getStatusLabel } from '../../position-list-table/status-select/status-select'

const contentParser: Record<
  keyof PositionVesselData | string,
  (cellContent: unknown, vessel: PositionVesselData) => string
> = {
  lastThreeCargos: (cellContent, vessel) => {
    return vessel?.lastThreeCargoesInternal?.lastThreeCargoes
      ? vessel.lastThreeCargoesInternal.lastThreeCargoes
      : getLastThreeVoyages(vessel?.voyages)
          ?.map((voyage: Voyage, i: number) => {
            return voyage.productCodes && voyage.productCodes
          })
          .join(' /') || ''
  },
  openDate: (cellContent) => {
    return cellContent ? formatDate(cellContent as string, 'dd-MM') : ''
  },
  openPort: (cellContent) => {
    return cellContent ? (cellContent as string) : ''
  },
  cbm: (cellContent) => {
    return cellContent ? Math.round(Number(cellContent)).toString() : ''
  },
  bunkerConsumption: (cellContent) => {
    const castContent = cellContent as Consumption
    return String(castContent?.ladenTonsPerDay || '')
  },
  imoClass: (cellContent, vessel) => {
    return cellContent && vessel.vesselStatus
      ? (cellContent as string).split(' ')?.[1]
      : (cellContent as string)
  },
  distances: (cellContent) => {
    return cellContent ? formatDate(cellContent as string, 'dd-MM') : ''
  },
  nextDryDock: (cellContent, vessel) => {
    const activeDryDock =
      vessel.nextDryDockInternal.nextDryDock || vessel.nextDryDock
    return activeDryDock ? formatDate(activeDryDock) : ''
  },
  lastUpdateGmt: (cellContent) => {
    return cellContent ? formatDate(cellContent as string, 'dd-MM HH:mm') : ''
  },
  usCoc: (cellContent) => {
    const { issued, lastAnnual } = cellContent as UsCoc
    return `${formatDate(issued)}<br>${formatDate(lastAnnual)}`
  },
  igs: (cellContent) => {
    return typeof cellContent === 'object' &&
      cellContent !== null &&
      'val' in cellContent
      ? (cellContent as any)?.val || ''
      : cellContent
  },
  igsSupply: (cellContent) => {
    return typeof cellContent === 'object' &&
      cellContent !== null &&
      'val' in cellContent
      ? (cellContent as any)?.val || ''
      : cellContent
  },
  tankCoating: (cellContent) => {
    return typeof cellContent === 'object' &&
      cellContent !== null &&
      'val' in cellContent
      ? (cellContent as any)?.val || ''
      : cellContent
  },
  dpp: (cellContent) => {
    return cellContent ? (cellContent as string) : 'N/A'
  },
}

const worldAreaMapping: WorldArea = {
  level1: 'Level 1',
  level2: 'Level 2',
  level3: 'Level 3',
}

const checkboxMapping = {
  openDate: 'Open Date',
  openPort: 'Open Port',
  lastThreeCargos: 'Last three cargos',
  positionListStatus: 'Status',
  comments: 'Comment',
}

const tdStyle = '"border:1px solid black;padding:8px;"'

export function generateHTMLTable(
  vesselData: AreaFilteredVessels[],
  multiSelectValues: MultiSelectValues,
  checkboxValues: CheckboxValues,
  columns: string[],
) {
  const headers = generateTableHeaders(multiSelectValues, checkboxValues)
  const body = generateHTMLTableBody(
    vesselData,
    multiSelectValues,
    checkboxValues,
    columns,
  )

  if (!headers || !body) {
    return ''
  }

  const table = `<table style="border:1px solid black;border-collapse:collapse;font-size:0.75rem;
  line-height: 1rem;color:${COLORS.secondary.darkBlue}">\n ${headers} ${body} </tbody>\n`
  return table
}

function generateTableHeaders(
  multiSelectValues: MultiSelectValues,
  checkboxValues: CheckboxValues,
) {
  let htmlTableColumnHeaders = ''

  htmlTableColumnHeaders += '<tr>\n'
  htmlTableColumnHeaders += `<th style=${tdStyle}>Vessel Name</th>\n`

  multiSelectValues.vesselSpecs.forEach((spec) => {
    const matchingSpec = vesselSpecsOptions.find(
      (option) => option.value === spec,
    )
    htmlTableColumnHeaders += `<th style=${tdStyle}>${
      matchingSpec?.label || spec
    }</th>\n`
  })

  multiSelectValues.eta.forEach((port) => {
    htmlTableColumnHeaders += `<th style=${tdStyle}><div>ETA ${port}</div></th>\n`
  })

  multiSelectValues.worldArea.forEach((area) => {
    htmlTableColumnHeaders += `<th style=${tdStyle}>${
      worldAreaMapping[area as keyof WorldArea]
    }</th>\n`
  })

  Object.entries(checkboxValues).forEach(([checkboxName, isChecked]) => {
    if (!isChecked) return
    htmlTableColumnHeaders += `<th style=${tdStyle}>
            ${checkboxMapping?.[checkboxName as keyof typeof checkboxMapping]}
          </th>`
  })

  return htmlTableColumnHeaders
}

function generateHTMLTableBody(
  vesselData: AreaFilteredVessels[],
  multiSelectValues: MultiSelectValues,
  checkboxValues: CheckboxValues,
  columns: string[],
  level = 0,
) {
  let htmlTableBody = ''

  const colSpanLength =
    multiSelectValues.vesselSpecs.length +
    multiSelectValues.eta.length +
    multiSelectValues.worldArea.length +
    Object.values(checkboxValues).filter((val) => val).length +
    1

  vesselData.forEach((item) => {
    // Filter vessels based on selectedVessels
    const selectedVessels = item?.vessels?.filter((vessel) =>
      multiSelectValues.selectedVessels.includes(vessel.vessel),
    )

    if (selectedVessels && selectedVessels.length > 0) {
      // Add the area header
      if (item.name) {
        htmlTableBody += `<tr style="border:1px solid black;text-align:center;background-color:${
          level === 0 ? COLORS.greys.mid : COLORS.primary.maerskBlue
        }"><td style="font-weight:bold;font-size:18px;">${
          item.name
        }</td>${`<td style=${tdStyle}></td>`.repeat(colSpanLength - 1)}</tr>\n`
      }

      htmlTableBody += '</tr>\n'
      htmlTableBody += '<tbody>\n'

      // Render vessel values
      selectedVessels.forEach((vessel) => {
        htmlTableBody += '<tr>\n'
        // Vessel name
        htmlTableBody += `<td style=${tdStyle}>${vessel.vessel}</td>\n`

        // Vessel specs
        multiSelectValues.vesselSpecs.forEach((spec) => {
          const cellContent = getCellContentForExport(vessel, spec)
          let renderedContent = ''
          if (contentParser[spec]) {
            renderedContent = contentParser[spec](cellContent, vessel)
          } else {
            renderedContent = cellContent ? (cellContent as string) : ''
          }
          htmlTableBody += `<td style=${tdStyle}>${renderedContent}</td>\n`
        })

        // ETA
        {
          multiSelectValues.eta.forEach((port) => {
            const eta = vessel?.voyages?.[0]?.distances?.find(
              (item) => item.port_name === port,
            )?.ETA
            htmlTableBody += `
            <td style=${tdStyle}>
              ${eta ? formatDate(eta) : ''}
            </td>
          `
          })
        }

        // World Area
        multiSelectValues.worldArea.forEach((area) => {
          const value = vessel?.worldArea?.[area as keyof WorldArea] || ''
          htmlTableBody += `
            <td style=${tdStyle}>
              ${value}
            </td>
          `
        })

        // Checkboxes
        {
          Object.entries(checkboxValues).forEach(
            ([checkboxName, isChecked]) => {
              if (!isChecked) return
              const cellContent = getCellContentForExport(vessel, checkboxName)
              let renderedContent = ''
              if (contentParser[checkboxName]) {
                renderedContent = contentParser[checkboxName](
                  cellContent,
                  vessel,
                )
              } else {
                renderedContent = isChecked ? (cellContent as string) : ''
              }
              htmlTableBody += `<td style=${tdStyle}>${renderedContent}</td>\n`
            },
          )
        }

        htmlTableBody += '</tr>\n'
      })

      htmlTableBody += '</tbody>\n'
    }

    // Recurse over the children
    if (item.children) {
      htmlTableBody += generateHTMLTableBody(
        item.children,
        multiSelectValues,
        checkboxValues,
        columns,
        level + 1,
      )
    }
  })

  return htmlTableBody
}

function getCellContentForExport(
  vessel: PositionVesselData,
  columnKey: string,
) {
  if (columnKey === 'positionListStatus' && vessel?.status) {
    const positionListStatus = // @ts-ignore
      (vessel.status as { positionListStatus: StatusValues }).positionListStatus

    if (positionListStatus === StatusValues.UNCERTAIN) {
      // business rule: if status is uncertain, do not show it
      return ''
    }

    return getStatusLabel(positionListStatus) || positionListStatus || ''
  }

  if (columnKey === 'comments') {
    if (vessel.comments.length === 0) {
      return ''
    }
    return vessel.comments[0].comment || ''
  }

  if (columnKey === 'lastSire') {
    return `${vessel.lastSire?.approval ? vessel.lastSire?.approval : 'N/A'} | ${vessel.lastSire?.port ? vessel.lastSire?.port : 'N/A'} | ${vessel.lastSire?.inspectionDate ? formatDate(vessel.lastSire.inspectionDate) : 'N/A'}`
  }

  return getCellContent(vessel, columnKey)
}
