import { ClickPoint, EditIcon } from '@maersktankersdigital/web-components'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from 'src/hooks/use-api'
import { ApiRoutes } from '~api/routes'
import { GetVesselRes } from '~hooks/queries/vessels/use-get-vessel'
import { Modal } from '~components/molecules/modal/modal'
import { EditVesselModal } from '~components/organisms/modals/vessel/edit-vessel-modal'
import { VesselFavoriteToggler } from '~components/organisms/tables/vessel/vessel-favorites-toggler'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useHasRole } from '~hooks/permissions/use-has-role'
import { Box } from '@mui/material'

export function HeaderActions({ vesselData }: { vesselData: GetVesselRes }) {
  const { vesselId } = useParams()
  const state = useVesselPageState()
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)

  const isAccountManager = useHasScope(ExactScopeName.accountManager)
  const isAdmin = useHasScope(ExactScopeName.superUser)
  const isMtOps = useHasScope(ExactScopeName.mtopsVessel)
  const isVesselOps = useHasRole({
    role: 'mtops',
    id: vesselId,
    entity: 'vessel',
  })

  const canSeeEditButton = isAccountManager || isAdmin || isMtOps || isVesselOps

  const vesselApiData = useApi<GetVesselRes>(
    vesselId ? ApiRoutes.Vessels.read : '',
    {
      vesselId,
    },
  )

  const handleCloseModal = () => {
    setIsOpenEditModal(false)
  }

  const handleToggleModal = () => {
    setIsOpenEditModal((prev) => !prev)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <VesselFavoriteToggler
        key={vesselId}
        vesselName={vesselApiData.data?.vesselName ?? vesselId!}
        vesselId={vesselId!}
        showText={false}
      />
      {!state.isVesselArchived && vesselData && canSeeEditButton && (
        <ClickPoint ariaLabel="edit vessel" onClick={handleToggleModal}>
          <EditIcon size={18} />
        </ClickPoint>
      )}
      <Modal
        title={`Edit vessel ${vesselData.vesselName}`}
        open={isOpenEditModal}
        onClose={handleCloseModal}
      >
        <EditVesselModal vesselData={vesselData} close={handleCloseModal} />
      </Modal>
    </Box>
  )
}
