import { Select } from '@maersktankersdigital/web-components'
import * as React from 'react'
import { useEffect, useState } from 'react'

import {
  IStatusUpdatePayload,
  IStatusUpdateResponse,
  userUpdateAction,
} from '~api/position-list/update'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import {
  StatusButton,
  StatusTableCell,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/status-select/status-select.styles'
import { StatusValues } from '~pages/pages-behind-login/position-list/types/position-list-types'

import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { Z_INDEX } from '~types/z-index'
import { trackNewStatusSelected } from '../../../tracking/track-new-status-selected'

type OptionType = { label: string; value: StatusValues }
export type GroupType = { label: string; options: OptionType[] }

export const statusOptions = [
  {
    label: 'Work',
    options: [
      { value: StatusValues.CLEANING, label: 'Cleaning' },
      { value: StatusValues.FOR_ORDERS, label: 'For Orders' },
      { value: StatusValues.IN_BALLAST, label: 'In Ballast' },
      { value: StatusValues.OPEN, label: 'Open' },
    ],
  },
  {
    label: 'Tentative',
    options: [
      { value: StatusValues.FIXED, label: 'Fixed' },
      { value: StatusValues.OFFERED, label: 'Offered' },
      { value: StatusValues.ON_HOLD, label: 'On Hold' },
      { value: StatusValues.ON_SUBS, label: 'On Subs' },
      { value: StatusValues.UNCERTAIN, label: 'Uncertain' },
    ],
  },
  {
    label: 'Do Not Work',
    options: [
      { value: StatusValues.DRY_DOCK, label: 'Dry Dock' },
      { value: StatusValues.REDELIVERY, label: 'Redelivery' },
      { value: StatusValues.TC_OUT, label: 'T/C Out' },
    ],
  },
]

export function getStatusLabel(statusValue: StatusValues): string | undefined {
  const statusLabelValuePairs = statusOptions.map((i) => i.options).flat()
  const option = statusLabelValuePairs.find(
    (option) => option.value === statusValue,
  )
  return option?.label
}

interface Props {
  status?: StatusValues
  vesselId: string
}

function StatusSelect({ status: fetchedStatus, vesselId }: Props) {
  const defaultStatus = fetchedStatus
  const { setStatus: SetApplicationStatus } = useAnimatedAlert()
  const isValidState =
    defaultStatus && Object.values(StatusValues).includes(defaultStatus)
  const initialStatus = isValidState ? defaultStatus : StatusValues.UNCERTAIN
  const [status, setStatus] = useState<StatusValues>()
  const isPositionListUser = useHasScope(ExactScopeName.positionListUpdate)

  useEffect(() => {
    setStatus(initialStatus)
  }, [initialStatus])

  const handleChangeStatus = async (value: string) => {
    await userUpdateAction<IStatusUpdatePayload, IStatusUpdateResponse>(
      {
        positionListStatus: value,
      },
      { vesselId },
    )
      .then((response) => {
        setStatus(response.positionListStatus as StatusValues)
      })
      .catch((error: Error) => {
        console.error(error)
        SetApplicationStatus(
          'Something went wrong when updating the status',
          5000,
          'error',
        )
      })
  }

  function handleSelectChange(
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null,
  ) {
    if (!value) return
    handleChangeStatus(value)
    trackNewStatusSelected(value)
  }

  return (
    <StatusTableCell value={status} isUser={isPositionListUser}>
      <Select
        slotComponents={{
          root: StatusButton as unknown as React.ComponentType<'button'>,
        }}
        slotProps={{
          root: { value: status },
          popper: { style: { zIndex: Z_INDEX.Popup } },
        }}
        name="status"
        options={statusOptions}
        value={status}
        onChange={handleSelectChange}
      />
    </StatusTableCell>
  )
}

export default StatusSelect
