import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { GoBackButton } from '~components/go-back-button'
import { sessionStorageItems } from '~constants/session-storage-items'
import { differenceInMinutes } from 'date-fns'

export function useErrorHandler() {
  useEffect(() => {
    if (shouldRefresh()) {
      setSessionStorageItemAndReload()
    }
  }, [])
}

function setSessionStorageItemAndReload() {
  sessionStorage.setItem(
    sessionStorageItems.lastRefresh,
    new Date().toISOString(),
  )
  window.location.reload()
}

export function shouldRefresh() {
  const expiryTimeInMinutes = 2

  const now = new Date()
  const lastRefreshTimeString = sessionStorage.getItem(
    sessionStorageItems.lastRefresh,
  )
  const lastRefreshTime = lastRefreshTimeString
    ? new Date(lastRefreshTimeString)
    : null

  if (lastRefreshTime instanceof Date && !isNaN(lastRefreshTime.getTime())) {
    return differenceInMinutes(now, lastRefreshTime) > expiryTimeInMinutes
  } else {
    return true
  }
}

export default function ErrorPage() {
  useErrorHandler()

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        mx: { xs: 4, md: 0 },
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ mt: { xs: 4, md: 8 }, mb: 5 }}>
          We are sorry!
        </Typography>
        <Typography sx={{ maxWidth: '520px', mb: 3 }}>
          There was an unexpected error. Please reload the page or click on the
          button below to go back to the home page.
        </Typography>
        <Typography sx={{ maxWidth: '520px', mb: 5, fontWeight: 'bold' }}>
          Want to report the issue? Click the "Report an Issue" widget on the
          right.
        </Typography>
        <GoBackButton forceHome />
      </Box>
    </Box>
  )
}
