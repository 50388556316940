import { useGetVessels } from '../queries/vessels/use-get-vessels'
import { ExactScopeName, useHasScope } from './use-has-scope'

export function useIsActiveUser(poolName?: string) {
  // Active user is a user who has at least one ACTIVE vessel in the pool
  // internal MT user is always considered active

  const { data, isLoading, isError } = useGetVessels({ poolName })
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)

  if (isMtUser) return { isActiveUser: true, isLoading: false, isError: false }

  const noActiveVessels = !data || data.length === 0
  return { isActiveUser: !noActiveVessels, isLoading, isError }
}
