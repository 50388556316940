import { useQuery } from '@tanstack/react-query'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useGetTabList({ vesselId }: { vesselId: string | undefined }) {
  const fetchRequest = useFetch<{ name: string; id: string }[]>()
  const url = `/tabs/${vesselId}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: Infinity,
    enabled: !!vesselId,
  })
}
