export const COLORS = {
  primary: {
    maerskBlue: '#42b0d5',
    white: '#ffffff',
  },
  secondary: {
    darkBlue: '#00243d',
    blue: '#193850',
    midBlue: '#0073ab',
    barBlue: '#0fa0d1',
    hoverBlue: '#57b9da',
    actionBlue: '#61c8eb',
    lightBlue: '#b5e0f4',
    notificationBlue: '#deedf4',
    blueTint: '#f0f9fd',
  },
  greys: {
    ultraDark: '#405a6e',
    dark: '#6c7f8d',
    mid: '#b3bdc5',
    light: '#e3e8ec',
    ultraLight: '#f7f8f9',
  },
  orange: {
    dark: '#a76d46',
    mid: '#f07508',
    light: '#ffa201',
    tint1: '#e69c56',
    tint2: '#eebd8b',
    tint3: '#f9d28b',
    tint4: '#fbe7c3',
    tint5: '#f6dec4',
  },
  red: {
    dark: '#6b0d05',
    mid: '#fa381c',
    light: '#ee9f92',
    tint1: '#f9d8d3',
    tint2: '#f9dcd4',
    tint3: '#fcefeb',
  },
  citrus: {
    dark: '#7c9d00',
    mid: '#a3bf00',
    light: '#d6e000',
    tint1: '#c0cd89',
    tint2: '#dfe6c3',
    tint3: '#d5de8c',
    tint4: '#e8efc2',
  },
  green: {
    dark: '#00786b',
    mid: '#4db5ab',
    light: '#e8f4f2',
    ash: '#88a59f',
  },
  feedback: {
    // not in design system
    green: {
      success: '#00cc00',
      successLight: '#ccffcc',
    },
    yellow: {
      warning: '#ffcc00',
      warningLight: '#ffffcc',
    },
    red: {
      error: '#B80012',
      errorLight: '#ffcccc',
    },
    blue: {
      notification: '#4da6',
      notificationLight: '#cce6',
    },
  },
}
