import { MinusIcon, PlusIcon } from '@maersktankersdigital/web-components'
import * as React from 'react'
import { forwardRef, ReactElement, useMemo, useState } from 'react'
import { useApi } from 'src/hooks/use-api'
import { ApiRoutes } from '~api/routes'
import { ApiConfig } from '~components/molecules/comment-field/comment-field'
import { generateUniqueKey } from '~components/organisms/tables/user/user-table'
import { loadAreas } from '~pages/pages-behind-login/position-list/contexts/filtered-data-provider'
import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import AISContent from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/ais-content/ais-content'
import PositionListCommentField from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/comment-field/position-list-comment-field'
import L3CContent from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/l3ccontent/l3ccontent'
import LastSireContent from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/last-sire-content/last-sire-content'
import NextDryDockField from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/next-dry-dock-field/next-dry-dock-field'
import { groupedColumnsConfig } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import {
  ExpandableContent,
  ExpandButtonCell,
  ShowCurrentVoyageButtonWrapper,
  TableCell,
  TableRow,
  VesselNameTableCell,
  VoyageTableRow,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import StatusSelect from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/status-select/status-select'
import VesselNameContent from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/vessel-name-content/vessel-name-content'
import VoyageTrackerPositionlist from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/voyage-tracker-position-list/voyage-tracker-positionlist'
import {
  Consumption,
  IComment,
  LastSire,
  PositionVesselData,
  StatusValues,
  UsCoc,
  WorldArea,
} from '~pages/pages-behind-login/position-list/types/position-list-types'
import { Voyage } from '~types/itinerary.types'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

interface Props {
  openVoyageRowIndex: number | null
  setOpenVoyageRowIndex: (index: number | null) => void
  vessel: PositionVesselData
  vesselIndex: number
}

export const formatDate = (
  string?: string,
  formatting = 'dd-MM-yyyy',
): string => {
  if (!string || typeof string !== 'string' || isNaN(Date.parse(string)))
    return 'N/A'
  const parts = string.split('-')
  if (parts.length < 3) return 'N/A'

  const year = parts[0]
  const month = parts[1]
  const day = parts[2].substring(0, 2)

  if (year < '2015') return 'N/A'
  switch (formatting) {
    case 'dd-MM-yyyy':
      return `${day}-${month}-${year}`
    case 'dd-MM':
      return `${day}-${month}`
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}`
    case 'yyyy-MM':
      return `${year}-${month}`
    default:
      return 'N/A'
  }
}

export const getCellContent = (
  vessel: PositionVesselData,
  columnKey: string,
) => {
  const isLoadArea = loadAreas.has(columnKey)
  const isLevel = /^level/.test(columnKey)

  if (isLoadArea) {
    const isBallastVoyage = vessel.isDuplicated || vessel.isDuplicatedParent
    if (isBallastVoyage) {
      const distanceETA = vessel.aisDistances.distances.find(
        (el) => el.port_name === columnKey,
      )?.ETA
      return distanceETA
    } else {
      const distanceETA = vessel.voyages[0]?.distances?.find(
        (el) => el.port_name === columnKey,
      )?.ETA
      return distanceETA
    }
  }

  if (isLevel) {
    return vessel.worldArea
      ? vessel.worldArea?.[columnKey as keyof WorldArea]
      : ''
  }
  return vessel[columnKey as keyof PositionVesselData]
}

const VesselListContent = forwardRef<HTMLTableRowElement, Props>(
  function VesselListContent(
    { openVoyageRowIndex, setOpenVoyageRowIndex, vessel, vesselIndex },
    ref,
  ) {
    const { activeView } = usePositionListView()
    const [currentAnchor, setAnchor] = useState<
      Record<number, HTMLElement | null>
    >({})
    const [currentPopupIndex, setCurrentPopupIndex] = useState<number | null>(
      null,
    )
    const { data: positionListStatusData } = useApi<PositionVesselData[]>(
      ApiRoutes.POSITION_LIST.STATUS,
      {},
      {
        // Refetch status every 15 seconds
        refreshInterval: 1000 * 15,
        dedupingInterval: 2000,
      },
    )
    const columns = activeView?.columns || []
    const voyageIsOpen = openVoyageRowIndex === vesselIndex
    const hasItineraryData =
      vessel?.voyages[0]?.itineraries &&
      vessel.voyages[0].itineraries.length > 0

    const handleOpenVoyage = (
      event: React.MouseEvent<HTMLDivElement>,
      i: number,
    ) => {
      setOpenVoyageRowIndex(i === openVoyageRowIndex ? null : i)
    }

    // Convert array to an object with vesselId as keys for quicker lookup
    const statusById = useMemo(() => {
      const obj: Record<string, StatusValues> = {}
      positionListStatusData?.forEach((item) => {
        obj[item.vesselId] = item.status
      })
      return obj
    }, [positionListStatusData])

    const commonRenderer = (
      parsedContent: string | ReactElement,
      columnsIndex: number,
      width: number,
    ) => {
      const isLast = columnsIndex === columns.length - 2

      return (
        <TableCell key={generateUniqueKey()} isLast={isLast} $width={width}>
          {typeof parsedContent === 'string'
            ? parsedContent.includes('/')
              ? parsedContent // If it contains '/', leave it as is
              : capitalizeStringWords(parsedContent) // Otherwise, capitalize the first letter
            : (parsedContent as ReactElement | string)}
        </TableCell>
      )
    }

    const columnRenderers: Record<
      keyof PositionVesselData | string,
      (
        cellContent: unknown,
        vessel: PositionVesselData,
        width: number,
        columnsIndex: number,
        vesselIndex: number,
      ) => JSX.Element | null
    > = {
      vessel: (cellContent, vessel, width, columnsIndex) => {
        const isLast = columnsIndex === columns.length - 2
        const key = `${vessel.vesselId}-${columnsIndex}`

        return (
          <VesselNameTableCell key={key} isLast={isLast} $width={width}>
            <VesselNameContent vesselData={vessel} />
          </VesselNameTableCell>
        )
      },
      voyages: (cellContent, vessel, width, columnsIndex, vesselIndex) => {
        const voyagesArray = cellContent as Voyage[]
        const isLast = columnsIndex === columns.length - 2
        const key = `${vessel.vesselId}-${columnsIndex}`
        return (
          <TableCell key={key} isLast={isLast} $width={width}>
            <L3CContent
              voyages={voyagesArray}
              internalLastThreeCargoes={vessel.lastThreeCargoesInternal}
              vesselName={vessel.vessel}
              vesselId={vessel.vesselId}
              currentAnchor={currentAnchor[vesselIndex]}
              isOpen={vesselIndex === currentPopupIndex}
              setAnchor={(anchor) => {
                const newAnchors = {
                  ...currentAnchor,
                  [vesselIndex]: anchor,
                } as Record<number, HTMLElement | null>
                setAnchor(newAnchors)
                setCurrentPopupIndex(vesselIndex)
              }}
            />
          </TableCell>
        )
      },
      status: (cellContent, vessel, width, columnsIndex) => {
        return (
          <StatusSelect
            key={`${vessel.vesselId}-${columnsIndex}`}
            status={statusById[vessel.vesselId] || vessel.status}
            vesselId={vessel.vesselId}
          />
        )
      },
      openDate: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent: string = cellContent
          ? formatDate(cellContent as string, 'dd-MM')
          : ''
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      cbm: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent: string = cellContent
          ? Math.round(Number(cellContent)).toString()
          : ''
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      bunkerConsumption: (cellContent, vessel, width, columnsIndex) => {
        const castContent = cellContent as Consumption
        const parsedContent = String(castContent?.ladenTonsPerDay || '')
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      imoClass: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent: string =
          cellContent && vessel.vesselStatus
            ? (cellContent as string).split(' ')?.[1]
            : (cellContent as string)
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      lastSire: (cellContent, vessel, width, columnsIndex) => {
        const castContent = cellContent as LastSire
        const key = `${vessel.vesselId}-${columnsIndex}`
        const isLast = columnsIndex === columns.length - 2

        return (
          <TableCell key={key} isLast={isLast} $width={width}>
            <LastSireContent lastSire={castContent} />
          </TableCell>
        )
      },
      distances: (cellContent, vessel, width, columnsIndex) => {
        const isBallastVoyage = vessel.isDuplicated || vessel.isDuplicatedParent
        const isLast = columnsIndex === columns.length - 2
        const parsedContent: string = cellContent
          ? formatDate(cellContent as string, 'dd-MM')
          : ''

        if (isBallastVoyage) {
          return (
            <TableCell key={generateUniqueKey()} isLast={isLast} $width={width}>
              <AISContent aisData={vessel.aisDistances}>
                {parsedContent}
              </AISContent>
            </TableCell>
          )
        }

        return commonRenderer(parsedContent, columnsIndex, width)
      },
      nextDryDock: (cellContent, vessel, width, columnsIndex) => {
        const key = `${vessel.vesselId}-${columnsIndex}`

        return <NextDryDockField vessel={vessel} key={key} width={width} />
      },
      lastUpdateGmt: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent: string = cellContent
          ? formatDate(cellContent as string, 'dd-MM HH:mm')
          : ''
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      sternLine: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent: string = cellContent
          ? (cellContent as string)
          : 'N/A'
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      comments: (cellContent, vessel, width, columnsIndex) => {
        const castContent = cellContent as IComment[]
        const key = `${vessel.vesselId}-${columnsIndex}`
        const apiConfig: ApiConfig = {
          endpoint: ApiRoutes.Vessels.Comments.update,
          params: {
            tab: 'positionlist',
            field: 'comment',
          },
          urlParams: { vesselId: vessel.vesselId },
        }

        return (
          <PositionListCommentField
            key={key}
            apiConfig={apiConfig}
            latestComment={castContent[0]}
            width={width}
          />
        )
      },
      showCurrentVoyage: (
        cellContent,
        vessel,
        width,
        columnsIndex,
        vesselIndex,
      ) => {
        const key = `${vessel.vesselId}-${columnsIndex}`
        const isOpen = openVoyageRowIndex === vesselIndex
        return (
          <ExpandButtonCell
            key={key}
            disabled={!hasItineraryData}
            width={width}
          >
            <ShowCurrentVoyageButtonWrapper
              onClick={(event) => handleOpenVoyage(event, vesselIndex)}
            >
              {isOpen ? <MinusIcon size={24} /> : <PlusIcon size={24} />}
            </ShowCurrentVoyageButtonWrapper>
          </ExpandButtonCell>
        )
      },
      usCoc: (cellContent, vessel, width, columnsIndex) => {
        const { issued, lastAnnual } = cellContent as UsCoc
        const parsedContent = (
          <>
            <div>{formatDate(issued)}</div>
            <div>{formatDate(lastAnnual)}</div>
          </>
        )
        return commonRenderer(parsedContent, columnsIndex, width)
      },
      igs: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent =
          typeof cellContent === 'object' &&
          cellContent !== null &&
          'val' in cellContent
            ? (cellContent as any)?.val || ''
            : (cellContent as string)

        const isLast = columnsIndex === columns.length - 2
        return (
          <TableCell key={generateUniqueKey()} isLast={isLast} $width={width}>
            {parsedContent}
          </TableCell>
        )
      },
      igsSupply: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent =
          typeof cellContent === 'object' &&
          cellContent !== null &&
          'val' in cellContent
            ? (cellContent as any)?.val || ''
            : (cellContent as string)

        const isLast = columnsIndex === columns.length - 2
        return (
          <TableCell key={generateUniqueKey()} isLast={isLast} $width={width}>
            {parsedContent}
          </TableCell>
        )
      },
      tankCoating: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent =
          typeof cellContent === 'object' &&
          cellContent !== null &&
          'val' in cellContent
            ? (cellContent as any)?.val || ''
            : Array.isArray(cellContent)
              ? cellContent.join('\n')
              : (cellContent as string)

        const isLast = columnsIndex === columns.length - 2
        return (
          <TableCell key={generateUniqueKey()} isLast={isLast} $width={width}>
            {parsedContent}
          </TableCell>
        )
      },
      dpp: (cellContent, vessel, width, columnsIndex) => {
        const parsedContent = cellContent ? (cellContent as string) : 'N/A'

        const isLast = columnsIndex === columns.length - 2
        return (
          <TableCell key={generateUniqueKey()} isLast={isLast} $width={width}>
            {parsedContent}
          </TableCell>
        )
      },
    }

    const renderDuplicationBackground =
      vessel.isDuplicated || vessel.isDuplicatedParent

    return (
      <>
        <TableRow
          ref={ref}
          $isDuplicated={renderDuplicationBackground}
          $isCommercialManagement={
            vessel.vesselStatus === 'commercial-management'
          }
        >
          {columns.map((columnKey, columnsIndex) => {
            const cellContent = getCellContent(vessel, columnKey)
            const isLoadArea = loadAreas.has(columnKey)
            const columnConfig = groupedColumnsConfig.find(
              (col) => col.value === columnKey,
            )
            const width = columnConfig?.width || 60

            const parsedColumnKey = isLoadArea ? 'distances' : columnKey
            if (columnRenderers[parsedColumnKey]) {
              return columnRenderers[parsedColumnKey](
                cellContent,
                vessel,
                width,
                columnsIndex,
                vesselIndex,
              )
            } else {
              return commonRenderer(cellContent as string, columnsIndex, width)
            }
          })}
        </TableRow>
        <VoyageTableRow>
          <td colSpan={columns.length}>
            <ExpandableContent
              style={{
                maxHeight: voyageIsOpen ? '1000px' : 0,
              }}
            >
              {hasItineraryData && (
                <VoyageTrackerPositionlist voyages={vessel.voyages} />
              )}
            </ExpandableContent>
          </td>
        </VoyageTableRow>
      </>
    )
  },
)

export default VesselListContent
